<template>
  <div class="notifications__item p-20" :class="{ readed: propItem.read_at }">
    <!-- Head -->
    <div class="notifications__item-head grid gtc-auto-1 ggap-10 ai-c mb-5">
      <a v-if="propItem.author" :href="profileLink + propItem.author.id" class="cover ava ava-30">
        <img :src="propItem.author.avatar || required('@/assets/img/ava.svg')" />
      </a>
      <div v-else class="cover ava ava-30">
        <img src="@/assets/img/ava.svg" />
      </div>
      <div class="flex ai-c jc-sb">
        <div class="grid ggap-5">
          <a v-if="propItem.author" :href="profileLink + propItem.author.id">{{ propItem.author.fullname }}</a>
          <span v-else>Аноним</span>
          <small class="fs-12 t-grey-ed">{{ formatRuDateTime(propItem.created_at) }}</small>
        </div>

        <button
          class="btn xsm rounded cube-20 p-0"
          :class="propItem.read_at ? 'outline' : 'grey'"
          :disabled="isLoad"
          v-tippy="propItem.read_at ? 'Пометить как НЕ прочитанное' : 'Пометить как прочитанное'"
          @click="setReadSratus()"
        >
          <BaseLoad v-if="isLoad" class="rel sm" />
          <template v-else>
            <BaseIcon v-if="propItem.read_at" class="ic-14 green" icon="check" />
            <BaseIcon v-else class="ic-10 secondary" icon="dot" />
          </template>
        </button>
      </div>
    </div>

    <!-- Body -->
    <div class="notifications__item-body pl-40 pt-5">
      <div class="notifications__item-body-message">
        <!-- <span class="mr-10"> -->
        <template v-if="propItem.data === 'like-to-post'">
          Лайкнул(а) ваш <RouterLink :to="`/news/${propItem.objects_id.post_id}`">пост</RouterLink>
        </template>
        <template v-else-if="propItem.data === 'subscribe-to-community'">
          Подписался(лась) на ваше сообщество
          <RouterLink
            v-if="propItem.objects_id.community"
            :to="propItem.objects_id.community.id ? `/communities/single/${propItem.objects_id.community.id}` : '#'"
          >
            {{ propItem.objects_id.community.title }}
          </RouterLink>
        </template>
        <template v-else-if="propItem.data === 'answer-to-comment'">
          Ответил(а) на ваш комментарий к посту
          <!-- <RouterLink :to="`/communities/single/${propItem.objects_id.community.id}`">
              {{ propItem.objects_id.community.title }}
            </RouterLink> -->
        </template>
        <template v-else-if="propItem.data === 'comment-to-post'">
          Оставил(а) комментарий к вашему посту
          <RouterLink :to="`/news/${propItem.objects_id.post_id}`">
            {{ propItem.objects_id.post_id }}
          </RouterLink>

          <div
            v-if="propItem.objects_id.comment && propItem.objects_id.comment.body"
            class="grid gtc-auto-1 ggap-10 ai-fs box pl-15 pr-15 mt-10"
          >
            <div class="pt-10"><BaseIcon class="ic-20 grey" icon="comment-circle" /></div>
            <div v-html="DOMPurify.sanitize(propItem.objects_id.comment.body)" />
          </div>
        </template>
        <template v-else>
          {{ propItem.data }}
        </template>
        <!-- </span> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { defineEmits, defineProps, toRefs, computed, ref, onMounted, inject } from 'vue'
import { BaseLoad, BaseIcon } from '@/components'
import { formatRuDateTime } from '@/plugins'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['update:item', 'updateRows'])

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { item } = toRefs(props)
const store = useStore()
const router = useRouter()
const profileLink = inject('profileLink')
const isLoad = ref(false)

// Computed
const propItem = computed({
  get: () => item.value,
  set: (val) => emits('update:item', val)
})

// Mounted
onMounted(() => {
  const links = document.querySelectorAll('.order-link')
  if (links?.length) {
    links.forEach((el) => {
      el.addEventListener('click', function () {
        router.push(el.dataset.link)
      })
    })
  }
})

// Methods
async function setReadSratus() {
  isLoad.value = true
  propItem.value.read_at = !propItem.value.read_at
  // const formData = new FormData()
  // formData.append('_method', 'PATCH')
  // formData.append('is_read', val ? 1 : 0)

  try {
    await store.dispatch('notifications/READ', item.value.id)
    emits('updateRows', false)
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
</script>

<style lang="scss">
.notifications__item {
  background-color: var(--box);
  border-top: var(--b1);

  &.readed {
    background-color: var(--form);
  }

  &:hover {
    background-color: var(--primary-el);
  }

  &-head {
    .cover {
      border: var(--b1);
    }
  }

  &-body {
    &-message {
      .order-link {
        border: 0;
        background-color: var(--box);
        font-weight: bold;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background-color: var(--primary);
          color: #fff;
        }
      }
    }
  }
}
</style>
